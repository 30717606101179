import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  postCategoryManage,
  deleleteCategory,
} from "../../../../store/Action/academyAction/seriesAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function ManageTestSeries() {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [deletes, setDelete] = useState(1);
  const itemsPerPage = 10; // Change this to the desired number of items per page

  // Logic to calculate total pages
  const queryParams = { page: currentPage, perPage: itemsPerPage };

  // Fetch categories and total count from API or any data source
  const fetchCategories = async () => {
    try {
      const response = await dispatch(postCategoryManage(queryParams));
      if (response) {
        setCategories(response.data.categories); // Update with the correct field name from your API response
        // setTotalCount(response.data.totalCount);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, [dispatch, currentPage, deletes]);

  // Logic to handle pagination controls
  const nextPage = () => {
    if (categories.length === itemsPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleDelete = (categoryId) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      const response = dispatch(deleleteCategory(categoryId));
      if (response) {
        setDelete(response);
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      }
    }
  };
  return (
    <div className="manage-category">
      <div className="card">
        <div className="card-header">Manage Test Series</div>
        <div className="card-body">
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Test Series Name</th>
                <th scope="col">Order</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) => (
                <tr key={category._id}>
                  <td>{category.name.length > 32
                    ? category.name.slice(0, 32) + "..."
                    : category.name || "N/A"}</td>
                  <td>{category.navViewOrder}</td>
                  <td>{category.status == 1 ? "Active" : "Inactive"}</td>
                  <td>
                    <Link to={`/academy/edittestseries/${category._id}`}>
                      <button
                        className="action-button"
                      //  onClick={() => handleEdit(category)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </Link>

                    <button
                      className="action-button"
                      onClick={() => handleDelete(category._id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                  {/* Add action buttons here */}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="card-footer bg-transparent border-secondary d-flex justify-content-center">
            <div className="pagination">
              <button
                className="pagination-btn"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="current-page">{currentPage}</span>

              <button
                className="pagination-btn"
                onClick={nextPage}
                disabled={categories.length < itemsPerPage}
              >
                {categories.length < itemsPerPage ? "" : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageTestSeries;
