import React, { useState, useEffect } from "react";
import "./category.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  getCategorys,
  postCategoryAdd,
  postCategoryUpdate,
} from "../../../../store/Action/academyAction/questionAction";

import axios from "axios";
import { clearLocalStorage } from "../../../../utils/localStrogaeUtil";
import Editor from "../../../../DecoupledEditor";

export default function AddTestQuestion() {
  const [inputValue, setInputValue] = useState("");
  const [editorLoaded, setEditorLoaded] = useState(false);

  const [optOne, setOptOne] = useState("");
  const [optTwo, setOptTwo] = useState("");
  const [optThree, setOptThree] = useState("");
  const [optFour, setOptFour] = useState("");
  const [correctOpt, setCorrectOpt] = useState("");
  const [ansExplanation, setAnsExplanation] = useState("");

  const [navViewOrder, setNavViewOrder] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("1");
  const [paperId, setPaperId] = useState("");
  const [paperList, setPaperList] = useState([]);

  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  const dispatch = useDispatch();
  // const history = useHistory();
  const id = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setEditorLoaded(true);
    // dispatch(getCategory());
  }, [dispatch]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleOptOne = (event) => {
    setOptOne(event.target.value);
  }

  const handleOptTwo = (event) => {
    setOptTwo(event.target.value);
  }

  const handleOptThree = (event) => {
    setOptThree(event.target.value);
  }

  const handleOptFour = (event) => {
    setOptFour(event.target.value);
  }

  const handleCorrectOpt = (event) => {
    setCorrectOpt(event.target.value);
  }

  const handlePaperChange = (value) => {
    setPaperId(value);
  }

  const handlenavViewOrderChange = (event) => {
    setNavViewOrder(event.target.value);
  }

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = { question: inputValue, questionOrder: Number(navViewOrder), status: selectedStatus, paperId, optOne, optTwo, optThree, optFour, correctOpt, ansExplanation };
      if (id.id) {
        let response = await dispatch(postCategoryUpdate(id.id, data));
        if (response) {
          navigate("/academy/managetestquestion");
        }
      } else {
        let response = await dispatch(postCategoryAdd(data));
        if (response) {
          navigate("/academy/managetestquestion");
        }
      }
    } catch (error) { }
  };

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const resp = await dispatch(getCategorys(id.id));
        if (resp) {
          setInputValue(resp.data.data.question);
          setNavViewOrder(resp.data.data.questionOrder.toString());
          setSelectedStatus(resp.data.data.status);
          setPaperId(resp.data.data.paperId);
          setOptOne(resp.data.data.optOne);
          setOptTwo(resp.data.data.optTwo);
          setOptThree(resp.data.data.optThree);
          setOptFour(resp.data.data.optFour);
          setCorrectOpt(resp.data.data.correctOpt);
          setAnsExplanation(resp.data.data.ansExplanation);
        }
      } catch (error) {
        // Handle error, e.g., show an error toast message
      }
    };

    if (id.id) {
      fetchCategory();
    }
  }, [dispatch, id.id]);

  useEffect(() => {
    const fetchTestpapers = async () => {
      try {
        let token = localStorage.getItem('token');
        const res = await axios.get(`${apiUrl}test-paper?status=1`, {
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        });
        setPaperList(res.data.data);
      } catch (error) {
        if (error?.response?.status === 401) {
          clearLocalStorage();
        }
        console.log(error);
      }
    };
    fetchTestpapers();
  }, []);

  return (
    <div>
      <div className="">
        <div className="line"></div>
        <div className="card">
          <div className="card-header">
            {id.id ? "Edit Test Question" : "Add Test Question"}
          </div>
          <div className="card-body">
            <form>
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="exampleFormControlSelect1">
                    Select Test Paper <span className="req-feild">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    value={paperId}
                    onChange={(event) => {
                      handlePaperChange(event.target.value);
                    }}
                  >
                    <option value="">Select Test Paper</option>
                    {paperList?.map((item, i) => (
                      <option key={i} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-12">
                  <label htmlFor="exampleText1">Add Test Question</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="exampleText1"
                    aria-describedby="emailHelp"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Add Question"
                    rows={4}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="navViewOrder">Question Order</label>
                  <input
                    type="text"
                    className="form-control"
                    id="navViewOrder"
                    value={navViewOrder}
                    onChange={handlenavViewOrderChange}
                    placeholder="Question Order"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="exampleFormControlSelect2">Select Status</label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect2"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="navViewOrder">Option 1</label>
                  <input
                    type="text"
                    className="form-control"
                    id="navViewOrder"
                    value={optOne}
                    onChange={handleOptOne}
                    placeholder="Option 1"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="navViewOrder">Option 2</label>
                  <input
                    type="text"
                    className="form-control"
                    id="navViewOrder"
                    value={optTwo}
                    onChange={handleOptTwo}
                    placeholder="Option 2"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="navViewOrder">Option 3</label>
                  <input
                    type="text"
                    className="form-control"
                    id="navViewOrder"
                    value={optThree}
                    onChange={handleOptThree}
                    placeholder="Option 3"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="navViewOrder">Option 4</label>
                  <input
                    type="text"
                    className="form-control"
                    id="navViewOrder"
                    value={optFour}
                    onChange={handleOptFour}
                    placeholder="Option 4"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="exampleFormControlSelect2">Select Correct Option</label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect2"
                    value={correctOpt}
                    onChange={handleCorrectOpt}
                  >
                    <option value="optOne">Option 1</option>
                    <option value="optTwo">Option 2</option>
                    <option value="optThree">Option 3</option>
                    <option value="optFour">Option 4</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="form-group ck-editor-container">
                  <label htmlFor="exampleText1">
                    Answer Explanation <span className="req-feild">*</span>
                  </label>
                  <Editor
                    name="ansExplanation"
                    value={ansExplanation}
                    onChange={(content) => {
                      setAnsExplanation(content);
                    }}
                    editorLoaded={editorLoaded}
                  />
                </div>
              </div>
            </form>
            <button
              type="button"
              className="btn btn-danger btn-lg"
              onClick={handleSubmit}
            >
              {id.id ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
