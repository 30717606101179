import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./dasboard.css";

export default function Sidebar({ sidebarActive }) {
  const [role, setRole] = useState("");

  useEffect(() => {
    let local_role = localStorage.getItem('role');
    setRole(local_role);
    console.log(sidebarActive, " :: from side barComponent");
  }, []);

  return (
    <>
      <nav id="sidebar" className={` ${sidebarActive ? "active" : ""}`}>
        <div className="sidebar-header">
          <h3 style={{ fontWeight: "bolder" }} ><a href="/">AgroNayan AI</a></h3>
        </div>

        <ul className="list-unstyled components">
          <li>
            <a
              href="#homeSubmenu"
              data-toggle="collapse"
              aria-expanded="false"
              className="dropdown-toggle"
            >
              News Category
            </a>
            <ul className="collapse list-unstyled" id="homeSubmenu">
              <li>
                <Link to='/addcategory' >Add News Category</Link>
              </li>
              <li>
                <Link to='/managecategory' >Manage News Category</Link>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="#newsSubmenu"
              data-toggle="collapse"
              aria-expanded="false"
              className="dropdown-toggle"
            >
              News Post
            </a>
            <ul className="collapse list-unstyled" id="newsSubmenu">
              <li>
                <Link to="/addnews">Add News</Link>
              </li>
              <li>
                <Link to="/trackernews">Tracker News</Link>
              </li>
              <li>
                <Link to="/managenews">Manage News</Link>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="#newsEmbeddingSubmenu"
              data-toggle="collapse"
              aria-expanded="false"
              className="dropdown-toggle"
            >
              News Embeddings
            </a>
            <ul className="collapse list-unstyled" id="newsEmbeddingSubmenu">
              <li>
                <Link to="/addnewsembedding">Add News Embedding</Link>
              </li>
              <li>
                <Link to="/managenewsembedding">Manage News Embedding</Link>
              </li>
              <li>
                <Link to="/importnewsembedding">Import EPN Embedding +</Link>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="#blogsCategorySubmenu"
              data-toggle="collapse"
              aria-expanded="false"
              className="dropdown-toggle"
            >
              Blogs Category
            </a>
            <ul className="collapse list-unstyled" id="blogsCategorySubmenu">
              <li>
                <Link to='/addblogscategory' >Add Blogs Category</Link>
              </li>
              <li>
                <Link to='/manageblogscategory' >Manage Blogs Category</Link>
              </li>
            </ul>
          </li>
          <li>
            <a
              href="#blogsSubmenu"
              data-toggle="collapse"
              aria-expanded="false"
              className="dropdown-toggle"
            >
              Blogs Post
            </a>
            <ul className="collapse list-unstyled" id="blogsSubmenu">
              <li>
                <Link to="/addblogs">Add Blogs</Link>
              </li>
              <li>
                <Link to="/manageblogs">Manage Blogs</Link>
              </li>
            </ul>
          </li>
          {/* Academy Section  */}
          <li>
            <a
              href="#academySubmenu"
              data-toggle="collapse"
              aria-expanded="false"
              className="dropdown-toggle"
            >
              Academy
            </a>
            <ul className="collapse list-unstyled" id="academySubmenu">
              <li>
                <Link to="/academy/addcategory">Add Category</Link>
              </li>
              <li>
                <Link to="/academy/managecategory">Manage Category</Link>
              </li>
              <li>
                <Link to="/academy/addtestseries">Add Test Series</Link>
              </li>
              <li>
                <Link to="/academy/managetestseries">Manage Test Series</Link>
              </li>
              <li>
                <Link to="/academy/addtestpaper">Add Test Paper</Link>
              </li>
              <li>
                <Link to="/academy/managetestpaper">Manage Test Paper</Link>
              </li>
              <li>
                <Link to="/academy/addtestquestion">Add Question</Link>
              </li>
              <li>
                <Link to="/academy/managetestquestion">Manage Question</Link>
              </li>
              {/* <li>
                <Link to="/addblogs">Add Test Series</Link>
              </li>
              <li>
                <Link to="/manageblogs">Manage Test Series</Link>
              </li>
              <li>
                <Link to="/addblogs">Add Test Paper</Link>
              </li>
              <li>
                <Link to="/manageblogs">Manage Test Paper</Link>
              </li>
              <li>
                <Link to="/addblogs">Add Questions</Link>
              </li>
              <li>
                <Link to="/manageblogs">Manage Questions</Link>
              </li> */}
            </ul>
          </li>
          {/* Magazine section */}
          <li>
            <a
              href="#magzinemenu"
              data-toggle="collapse"
              aria-expanded="false"
              className="dropdown-toggle"
            >
              Magzine
            </a>
            <ul className="collapse list-unstyled" id="magzinemenu">
              <li>
                <Link to="/addmagzine">Add Magzine</Link>
              </li>
              <li>
                <Link to="/managemagzine">Manage Magzine</Link>
              </li>
            </ul>
          </li>
          {/* Video section  */}
          <li>
            <a
              href="#videosmenu"
              data-toggle="collapse"
              aria-expanded="false"
              className="dropdown-toggle"
            >
              Videos
            </a>
            <ul className="collapse list-unstyled" id="videosmenu">
              <li>
                <Link to="/addvideos">Add Videos</Link>
              </li>
              <li>
                <Link to="/managevideos">Manage Videos</Link>
              </li>
            </ul>
          </li>
        </ul>
        <ul className="list-unstyled CTAs d-flex justify-content-around auth-btns">
          {/* <li>
            <Link to="/login" type="button" className="btn btn-outline-primary btn-lg" style={{ fontSize: "1rem" }}>Log In</Link>
          </li> */}
          {role === "superadmin" && <li>
            <Link to="/register" type="button" className="btn btn-outline-success btn-lg" style={{ fontSize: "1rem" }}>Register</Link>
          </li>}
        </ul>
      </nav>
    </>
  );
}

// const [sidebarActive, setSidebarActive] = useState(false);

// Function to toggle the sidebar
// const toggleSidebar = () => {
//   console.log('Toggle Sidebar clicked');
//   setSidebarActive(!sidebarActive);
// };